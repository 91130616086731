export default {
    0: {
        short: 'Service Requested',
        button: '',
        class: 'warning',
        color: '#F0B323',
        weight: '900',
        icon: 'fa-solid fa-screwdriver-wrench',
        unicode: '\uf7d9',
        descriptor: 'View Service',
        action: '/service-history/',
    },
    1: {
        short: 'Service Accepted',
        button: '',
        class: 'warning',
        color: '#F0B323',
        weight: '900',
        icon: 'fa-solid fa-screwdriver-wrench',
        unicode: '\uf7d9',
        descriptor: 'View Service',
        action: '/service-history/',
    },
    2: {
        short: 'Service Scheduled',
        button: '',
        class: 'success',
        color: '#00A76B',
        weight: '900',
        icon: 'fa-solid fa-screwdriver-wrench',
        unicode: '\uf7d9',
        descriptor: 'View Service',
        action: '/service-history/',
    },
    3: {
        short: 'Service In Progress',
        button: '',
        class: 'success',
        color: '#00A76B',
        weight: '900',
        icon: 'fa-solid fa-screwdriver-wrench',
        unicode: '\uf7d9',
        descriptor: 'View Service',
        action: '/service-history/',
    },
    4: {
        short: 'Service Completed',
        button: '',
        class: 'success',
        color: '#00A76B',
        weight: '300',
        icon: 'fa-light fa-truck-moving',
        unicode: '\uf4df',
        descriptor: 'View Service',
        action: '/service-history/',
    },
    5: {
        short: 'Service Failed',
        button: '',
        class: 'danger',
        color: '#da4333',
        weight: '900',
        icon: 'fa-solid fa-screwdriver-wrench',
        unicode: '\uf7d9',
        descriptor: 'View Service',
        action: '/service-history/',
    },
    6: {
        short: 'PM Coming Due',
        button: '',
        class: 'warning',
        color: '#F0B323',
        weight: '900',
        icon: 'fa-solid fa-calendar-days',
        unicode: '\uf073',
        descriptor: 'Schedule PM',
        action: '/service/request/',
    },
    7: {
        short: 'PM Past Due',
        button: '',
        class: 'danger',
        color: '#da4333',
        weight: '900',
        icon: 'fa-solid fa-calendar-days',
        unicode: '\uf073',
        descriptor: 'Schedule PM',
        action: '/service/request/',
    },
    8: {
        short: 'Checking Status',
        button: '',
        class: 'disabled',
        color: '#878a92',
        weight: '900',
        icon: 'fa-duotone fa-radar',
        unicode: '\ue024',
        descriptor: 'Please wait...',
        action: '/service/request/',
    },
    99: {
        short: 'No Issues Reported',
        button: '',
        class: 'success',
        color: '#00A76B',
        weight: '300',
        icon: 'fa-light fa-truck-moving',
        unicode: '\uf4df',
        descriptor: 'Request Service',
        action: '/service/request/',
    }
}