<template><div></div></template>
<script>
import {computed, nextTick, onMounted, watch} from "@vue/runtime-core";
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";
import {ref} from "@vue/reactivity";
import {toRaw} from "vue";

export default {
    props: {
        map: Object,
        markers: Array,
        selected: Function,
        label: {
            type: Function,
            default(markers, position, count){
                return {
                    text: count.toString(),
                    fontFamily: "Montserrat",
                    color: '#FFFFFF',
                    fontSize: "16px",
                    fontWeight: "300",
                    cssClass: "test"
                }
            },
        },
        icon: {
            type: Function,
            default(markers, position, count){
                return {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 15 + Math.floor(count / 100) * 5,
                    fillColor: "#161F35",
                    fillOpacity: 1,
                    strokeWeight: 5 + count / 10,
                    strokeOpacity: 0.6,
                    strokeColor: "#161F35",
                }
            },
        },
        renderer: {
            type: Object,
        },
        algorithm: {
            type: Function,
            default: SuperClusterAlgorithm,
        },
        maxZoom: {
            type: Number,
            default: 10,
        },
        radius: {
            type: Number,
            default: 140,
        },

    },
    emits: ['selected:marker', 'ran:render'],
    setup: (props, { emit }) => {                
        let renderRan = ref(false);
        watch(renderRan, () => {
            if (renderRan.value){
                emit('ran:render', nextClusters);
            }
        });
        let clusterer;
        let lastClusters = [];
        let nextClusters = [];
        let lastMarkers = [];
        let nextMarkers = [];
        
        let renderer = props.renderer || {
            render: ({markers, position, count}) => {
                renderRan.value = true;
                markers.forEach(x => nextClusters.push(x.id));
                return new window.google.maps.Marker({
                    position,
                    label: props.label(markers, position, count),
                    icon: props.icon(markers, position, count),
                    zIndex: 1,
                });
            }
        }
        
        const setVisible = () => {
            nextMarkers = [];
            //remove any markers that aren't in the new array
            for (let i in lastMarkers){
                if (props.markers.indexOf(lastMarkers[i]) === -1){
                    clusterer.removeMarker(lastMarkers[i], true);
                }
            }
            //add everything in the new array (clusterer internally checks for dupes)
            for (let i in props.markers){
                nextMarkers.push(props.markers[i]);
                clusterer.addMarker(props.markers[i], true);
            }
            lastMarkers = nextMarkers;
            //render once after all changes
            clusterer.render();
        };
        
        const markers = computed(() => {
            return props.markers;
        }) 
        
        //remove this watcher
        
        onMounted(()=> {
            
            const algorithm = new props.algorithm({
                maxZoom: props.maxZoom,
                radius: props.radius,
            });



            nextTick(() => {                
                clusterer = new MarkerClusterer({
                    map: toRaw(props.map),
                    markers: Object.values(props.markers),
                    algorithm: algorithm,
                    renderer: renderer
                });

                //need to track if there were any marker changes to avoid extraneous
                //map redraws
                clusterer.addListener('clusteringbegin', () => {
                    renderRan.value = false;
                    nextClusters = [];
                });
                clusterer.addListener('clusteringend', () => {
                    if (
                        renderRan.value
                        && (
                            lastClusters.length !== nextClusters.length
                            || nextClusters.some(x => !lastClusters.includes(x))
                        )
                    ){
                        lastClusters = nextClusters;
                    }
                });

                watch(markers, () => {
                    setVisible();
                });
                setVisible();
            });
        });
        
        const forceUpdate = () => {
            clusterer.removeMarker(lastMarkers[0], true);
            clusterer.addMarker(lastMarkers[0], false);
        } 
        
        return {
            forceUpdate,
            renderRan,
        }
        
        
    }
}
</script>

<style scoped>

</style>