<template>
    <div class="fleet-dashboard dashboard">

        <header class="page-header">
            <div>
                <client-account-header></client-account-header>
                <h2>Fleet Dashboard</h2>
            </div>
            
            <div class="header-controls">
                <router-link to="/service/request" class="button"><i class="fa-light fa-screwdriver-wrench"></i> Request Service</router-link>
            </div>
        </header>
        <div class="dashboard-wrapper" v-if="loaded">
            <transition name="sidebar-left" appear>
                <div class="sidebar-left">


                    <div class="card-search">
                        <i class="fas fa-search"></i>
                        <input type="text" v-model="searchTerm" @keydown="$event.keyCode !== 27 || (searchTerm = '')" />
                        <a @click.stop="filterOpen = !filterOpen" class="filter-toggle">
                            <span>{{[statusFilter, pmFilter, makeFilter, yearFilter].reduce((a, b, i) => a + (+(b !== '')), 0) || ''}}</span>
                            <i class="fa-regular fa-bars-filter"></i>
                        </a>
                    </div>


                    <div class="cards list-wrapper" ref="cardWrapper" v-if="Object.keys(derivedStatus).length">
                        <transition-group name="cross-fade">
                            <div class="card" 
                                v-for="id in filteredEquipment" 
                                :key="id" 
                                @click="selection = id"
                                :class="{selected: selection === id}"
                            >
                                <div class="card-icon" :class="ServiceStatusDisplay[derivedStatus[id][0]].class"><i :class="ServiceStatusDisplay[derivedStatus[id][0]].icon"></i></div>
                                <div>
                                    <template v-if="equipment[id]._loaded">
                                        <h4 v-html="equipment[id].trailerNumber.replace(searchTerm, `<span>${searchTerm}</span>`)"></h4>
                                        <span>{{derivedStatus[id].length ? listValue(derivedStatus[id].map(x => ServiceStatusDisplay[x].short), {multiple: 'list'}) : 'No Issues Reported'}}</span>
                                    </template>
                                    <template v-else>
                                        <h4>Loading...</h4>
                                        <span>{{ServiceStatusDisplay[8].short}}</span>
                                    </template>
                                    <div class="card-icons"><img src="@/assets/images/brand/star.svg" alt="Star Leasing" v-if="equipment[id].valueof.owner === 1" /></div>
                                </div>
                            </div>
                            <div key="a" v-if="Object.keys(filteredEquipment).length === 0" class="card">
                                <div @click.stop="statusFilter = pmFilter = searchTerm = makeFilter = yearFilter = ''">
                                    <h4>No trailers to display</h4>
                                    <span>Click to clear filters</span>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    
                    <transition name="autocordion">
                        <div class="autocordion filter-wrapper" v-if="filterOpen" @click.stop>
                            <div>
                                <div class="equipment-filters">
                                    <fieldset class="form-group">
                                        <h5>Service Status</h5>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value=""><span><i class="fas fa-tools"></i> Any Maintenance Status</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value="0"><span><i class="warning fas fa-tools"></i> Requested</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value="1"><span><i class="warning fas fa-tools"></i> Request Accepted</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value="2"><span><i class="success fas fa-tools"></i> Scheduled</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value="3"><span><i class="success fas fa-tools"></i> In Progress</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value="4"><span><i class="success fas fa-truck-moving"></i> Fully Operational</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="statusFilter" value="5"><span><i class="danger fas fa-tools"></i> Failed Repair</span>
                                        </label>
                                    </fieldset>

                                    <fieldset class="form-group">
                                        <h5>PM Status</h5>
                                        <label>
                                            <input type="radio" v-model="pmFilter" value=""><span><i class="far fa-calendar-alt"></i> Any PM Status</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="pmFilter" value="0"><span><i class="success fas fa-truck-moving"></i> PM Up To Date</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="pmFilter" value="1"><span><i class="warning far fa-calendar-alt"></i> PM Coming Due</span>
                                        </label>
                                        <label>
                                            <input type="radio" v-model="pmFilter" value="2"><span><i class="danger far fa-calendar-alt"></i> PM Overdue</span>
                                        </label>
                                    </fieldset>
                                    <div class="form-group">
                                        <span @click="collapsibles['make'] = !collapsibles['make']" class="collapse-control" :class="{open: collapsibles['make']}"><i class="fa-regular fa-chevron-down"></i> Make</span>
                                        <transition name="autocordion">
                                            <div class="autocordion" v-if="collapsibles['make']">
                                                <div>
                                                    <label>
                                                        <select v-model="makeFilter">
                                                            <option :value="''">Any</option>
                                                            <option v-for="make in makes" :key="make.id" :value="make.id">{{make.name}}</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="form-group">
                                        <span @click="collapsibles['year'] = !collapsibles['year']" class="collapse-control" :class="{open: collapsibles['year']}"><i class="fa-regular fa-chevron-down"></i> Year</span>
                                        <transition name="autocordion">
                                            <div class="autocordion" v-if="collapsibles['year']">
                                                <div>
                                                    <label>
                                                        <input type="text" v-model="yearFilter" placeholder="Enter range (ex: 2012, or 2016-2024)" />
                                                    </label>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="form-controls">
                                        <button @click="statusFilter = pmFilter = searchTerm = makeFilter = yearFilter = ''" class="button-2">Clear</button>
                                        <button @click="filterOpen = false">Close</button>
                                    </div>                      
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </transition>
            
            <div class="dashboard-viewport">
                
                <transition name="dashboard-overview" appear>
                    <div class="dashboard-overview">
                        <Map @map:initialized="mapSetup">
                            <template #clusters="{map}">
                                <Clusters :map="map" @ran:render="clusteredTrailers = $event" ref="trailerClusterer" :markers="activeTrailerMarkers"></Clusters>
                            </template>
                        </Map>
                        <div class="map-controls">
                            <span @click="mapLock = !mapLock" :class="{active: !mapLock}"><i class="fa-solid" :class="mapLock ? 'fa-unlock' : 'fa-lock'"></i></span>
                            <span @click="toggleShops" :class="{active: shopMarkersActive}"><img src="@/assets/images/brand/star.svg" alt="Star Leasing" /></span>
                            <span @click="toggleLocations"></span>
                        </div>
                    </div>
                </transition>
                
                <transition name="dashboard-detail" appear>
                    <div class="dashboard-detail fleet-details">
                        
                        <template v-if="selection && equipment[selection]._loaded">
                            <div class="details-top">
                                <div>
                                    <h3>
                                        <img src="@/assets/images/brand/star.svg" alt="Star Leasing" v-if="equipment[selection].valueof.owner === 1" />
                                        {{equipment[selection].trailerNumber}}
                                    </h3>
                                    <h4>{{equipment[selection].year}} {{equipment[selection].make.name}} {{equipment[selection].nominalLength}}' {{equipment[selection].description}}</h4>
                                </div>
                                <div class="status-buttons">
                                    <div v-for="(status, i) in derivedStatus[selection]" :key="i">
                                        <h5>{{ServiceStatusDisplay[status].short}}</h5>
                                        <router-link :to="`${ServiceStatusDisplay[status].action}${status < 6 ? equipment[selection].valueof.activeServiceRequests[i] : equipment[selection].id }`" :class="ServiceStatusDisplay[status].class" class="button">
                                            <i :class="status === 99 ? 'fa-light fa-screwdriver-wrench' : ServiceStatusDisplay[status].icon"></i>{{ServiceStatusDisplay[status].descriptor}}
                                        </router-link>
                                    </div>
                                    <div key="a" v-if="derivedStatus[selection][0] !== 99">
                                        <h5>&nbsp;</h5>
                                        <router-link :to="`${ServiceStatusDisplay[99].action}${equipment[selection].id}`" :class="ServiceStatusDisplay[99].class" class="button">
                                            <i :class="'fa-light fa-screwdriver-wrench'"></i>{{ServiceStatusDisplay[99].descriptor}}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="expander" :class="{open: collapsibles.details}" @click="collapsibles.details = !collapsibles.details"><i class="fa-solid fa-chevron-up"></i>{{collapsibles.details ? 'Less detail' : 'More detail'}}</div>
                            </div>
                            <div class="details-wrapper" :class="{'longer': collapsibles.details}">
                                <div class="details-short" :class="{open: !collapsibles.details}">
                                    <div>Short</div>
                                </div>
                                <div class="details-long" :class="{open: collapsibles.details}">
                                    <div>Long<br /><br /><br /><br /><br /><br /><br /></div>
                                </div>
                            </div>
                        </template>
                        
                        <template v-else>
                            <div class="details-top">
                                <div>
                                    <h3>Select equipment...</h3>
                                </div>
                            </div>
                            <div class="details-wrapper">
                            </div>
                        </template>
                        
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch} from '@vue/runtime-core';
import ShopLocationModel from '../models/ShopLocationModel';
import FormatDataMixin from '../mixins/FormatData';
import EquipmentModel from '../models/EquipmentModel';
import ClientAccountHeader from '../components/clientAccounts/ClientAccountHeader.vue';
import ServiceStatusDisplay from '../static/ServiceStatusDisplay.js';
import MakeModel from '../models/MakeModel';

import ServiceRequestModel from '../models/ServiceRequestModel';
import database from "@/store/database";
import axiosInstance from "@/connections/AxiosInstance";
import GPSModel from "@/models/GPSModel";
import Map from "@/components/map/Map";
import Clusters from "@/components/map/Clusters";

export default {
    components: {Clusters, Map, ClientAccountHeader},
    setup() {
        //utilities
        const {output, listValue} = FormatDataMixin();

        //general vars
        const loaded = ref(false);
        const promises = [];
        const detailView = ref(0);
        const filterOpen = ref(false);

        const statusFilter = ref('');
        const pmFilter = ref('');
        const makeFilter = ref('');
        const yearFilter = ref('');
        const searchTerm = ref('');

        const collapsibles = reactive({});
        const selection = ref(false);
        const mapBoundChange = ref(0);
        const mapLock = ref(false);
        const cardWrapper = ref();
        let map;
        const clusteredTrailers = ref([]);
        
        const today = new Date(Math.floor(new Date().getTime() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000));
        const nextMonth = new Date(Math.floor(new Date(new Date().setMonth(new Date().getMonth() + 1)).getTime() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000));


        //reference all the models we're playing with
        const shops = database[ShopLocationModel.name];
        const makes = database[MakeModel.name];
        const serviceRequests = database[ServiceRequestModel.name];
        const equipment = database[EquipmentModel.name];

        const gpsData = reactive({});
        promises.push(axiosInstance.getAll(GPSModel).then((response) => {
            for (var i in response.data){
                gpsData[i] = response.data[i];
            }
            return response;
        }));
        
        const filteredEquipment = reactive([...equipment.list]);
        promises.push(...equipment.promises);

        watch(
            [
                statusFilter,
                pmFilter,
                yearFilter,
                makeFilter,
                searchTerm,
                equipment.keys,
                serviceRequests.keys,
                mapBoundChange,
                mapLock,
                loaded,
            ], () => {
                nextTick(() => {
                    console.log("filteredEquipment");
                    let build = [...equipment.keys];

                    if (mapLock.value === true && statusFilter.value === '' && pmFilter.value === '' && makeFilter.value === '' && yearFilter.value.length < 2 && searchTerm.value === ''){
                        filteredEquipment.length = 0;
                        filteredEquipment.push(...build);
                        return;
                    }
                    const numberStatusFilter = statusFilter.value === '' ? '' : parseInt(statusFilter.value);
                    const derivedYear = yearFilter.value.replace(/ /g,'').split("-").map(x => x !== undefined && x.length === 2 ? parseInt(`20${x}`) : parseInt(x));

                    build = build.filter(id => {
                        let trailer = equipment[id];
                        return (
                            //everything 
                            numberStatusFilter === ''
                            //or no active requests if "fully operational" 
                            || numberStatusFilter === 4 && trailer.valueof.activeServiceRequests.length === 0
                            //or any active requests matching any other type
                            || derivedStatus[id].some(x => x === numberStatusFilter)
                        ) && (
                            //everything
                            pmFilter.value === ''
                            //or up to date if more than a month away
                            || pmFilter.value === '0' && trailer.nextPMDate >= nextMonth
                            //or between today and a month from now
                            || pmFilter.value === '1' && trailer.nextPMDate < nextMonth && trailer.nextPMDate >= today
                            //or you've already missed it mate 
                            || pmFilter.value === '2' && trailer.nextPMDate < today
                        ) && (
                            //year is between two number, or is one number
                            yearFilter.value === ''
                            || derivedYear[1] && trailer.year <= derivedYear[1] && trailer.year >= derivedYear[0]
                            || derivedYear[1] === undefined && derivedYear[0] && trailer.year === derivedYear[0]
                        ) && (
                            //if there's a make, check for a match
                            makeFilter.value === '' || trailer.valueof.make === makeFilter.value
                        ) && (
                            //if there's a search term, it's somewhere in our trailer number
                            searchTerm.value === '' || trailer.trailerNumber.indexOf(searchTerm.value) !== -1
                        )
                    });
                    
                    if (mapLock.value === false && map && map.renderingType !== 'UNINITIALIZED'){
                        let bounds = map.getBounds();
                        build = build.filter((id) => {
                            return bounds.contains(trailerMarkers[id].getPosition());                            
                        });
                    }

                    filteredEquipment.length = 0;
                    filteredEquipment.push(...build);
                });
            }
        );

        //Trailer statuses derived from record information
        const derivedStatus = reactive({});
        
        //only update status when one of these changes:
        watch(
            [
                loaded,
                equipment.keys,
                equipment.loaded,
                serviceRequests.keys,
                serviceRequests.loaded,
            ], () => {
                //needs to happen after service request collections are updated
                nextTick(() => {
                    console.log("derivedStatus");
                    let build = {};
                    for (var i in filteredEquipment){
                        let id = filteredEquipment[i];
                        let trailer = equipment[filteredEquipment[i]];
                        build[id] = [];

                        if (trailer._loaded === false){
                            build[id].push(8);
                            continue;
                        }
                        if (trailer.activeServiceRequests._loaded === false){
                            trailer.activeServiceRequests.load();
                            build[id].push(8);
                            continue;
                        }

                        if(trailer.activeServiceRequests.length > 1){
                            trailer.activeServiceRequests
                                .sort((x, y) => {x.valueof.status - y.valueof.status})
                                .map(x => x === undefined || build[id].push(x.valueof.status));
                        } else if (trailer.activeServiceRequests.length){
                            build[id].push(trailer.activeServiceRequests.first().valueof.status);
                        }
                        if (trailer.valueof.nextPMDate < today){
                            build[id].push(7);
                        } else if (trailer.valueof.nextPMDate < nextMonth){
                            build[id].push(6);
                        }
                        if (build[id].length === 0){
                            build[id].push(99);
                        }
                    }
                    Object.assign(derivedStatus, build);
                    
                });
            }
        );
        


        const close = function(){
            filterOpen.value = false;
        };
        onMounted(() => {
            window.addEventListener("click", close); 
        });
        onBeforeUnmount(() => {
            window.removeEventListener("click", close);
        });









        /*MAP STUFF*/
        const trailerClusterer = ref();
        const trailerMarkers = {};
        const shopMarkers = {};
        let shopMarkersActive = ref(false);
        //cross of filtered equipment and all markers
        const activeTrailerMarkers = computed(() => {
            return Object.values(trailerMarkers).filter(x => filteredEquipment.indexOf(parseInt(x.id)) !== -1);
        });
        const toggleShops = () => {
            shopMarkersActive.value = !shopMarkersActive.value;
            if (shopMarkersActive.value){
                Object.values(shopMarkers).forEach(x => x.setMap(map));
            } else {
                Object.values(shopMarkers).forEach(x => x.setMap(null));
            }
        }
        
        //sets/updates trailer icon by derived status
        const setTrailerMarkerStatus = function (id){
            if (!derivedStatus[id]){
                return;
            }
            let status = derivedStatus[id][0];
            trailerMarkers[id].setLabel({
                text: ServiceStatusDisplay[status].unicode,
                fontWeight: ServiceStatusDisplay[status].weight,
                fontFamily: "Font Awesome 6 Pro",
                color: '#FFFFFF',
                fontSize: "16px"
            });
            trailerMarkers[id].setIcon({
                path: "M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z",
                scale: 0.72,
                fillColor: ServiceStatusDisplay[status].color,
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: "#161F35",
                labelOrigin: new window.google.maps.Point(33, 25),
                anchor: new window.google.maps.Point(15.5, 45),
            });
            trailerMarkers[id].setZIndex(Math.floor(100 / status));
        }
        
        //active selected marker styling/handling
        watch(selection, (id, lastId) => {
            trailerMarkers[id].setIcon({
                path: "M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z",
                scale: 0.72,
                fillColor: ServiceStatusDisplay[derivedStatus[id][0]].color,
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: "#161F35",
                labelOrigin: new window.google.maps.Point(33, 25),
                anchor: new window.google.maps.Point(15.5, 45),
            });
            trailerMarkers[id].setZIndex(100);
            //if this is in a cluster, also show it separately
            if (clusteredTrailers.value.includes(id+'') && map.getZoom() <= 10){
                trailerMarkers[id].setMap(map);
            }
            if (lastId){
                trailerMarkers[lastId].setIcon({
                    path: "M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z",
                    scale: 0.72,
                    fillColor: ServiceStatusDisplay[derivedStatus[lastId][0]].color,
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: "#161F35",
                    labelOrigin: new window.google.maps.Point(33, 25),
                    anchor: new window.google.maps.Point(15.5, 45),
                });
                trailerMarkers[lastId].setZIndex(Math.floor(100 / derivedStatus[lastId][0]));
                //if this is in a cluster, stop showing it separately
                if (clusteredTrailers.value.includes(lastId+'') && map.getZoom() <= 10){
                    trailerMarkers[lastId].setMap(null);
                }
            }
        });
        
        //don't update list until we've stopped moving the map
        var lazyRelist;

        const starMapIcon = computed(() => {
            return require('../assets/images/brand/star-marker.svg');
        });
        
        //map setup function, for once the map object is ready 
        const mapSetup = (m) => {
            map = m;
            console.log("Map initializing");
            for (let id in equipment){

                //add the marker
                let marker = {
                    position: {
                        lat: parseFloat(gpsData[id].latitude),
                        lng: parseFloat(gpsData[id].longitude)
                    },
                    map: null,
                    type: 'trailer',
                    id: id,
                }
                trailerMarkers[id] = new window.google.maps.Marker(marker);
                //set initial status icon
                setTrailerMarkerStatus(id);
                //click handler
                trailerMarkers[id].addListener('click', () => {
                    selection.value = parseInt(id);
                    nextTick(() => {
                        let offsetTop = document.querySelector(`.cards .selected`).offsetTop;
                        if (offsetTop > cardWrapper.value.offsetHeight){
                            cardWrapper.value.scrollTop = offsetTop - 50;
                        }
                    });
                });
                
            }
            
            for (let id in shops){
                let marker = {
                    position: {
                        lat: parseFloat(shops[id].latitude),
                        lng: parseFloat(shops[id].longitude)
                    },
                    map: shopMarkersActive.value ? map : null,
                    type: 'shop',
                    id: id,
                    zIndex: 99,
                    icon:
                        {
                            url: starMapIcon.value,
                            scaledSize: new window.google.maps.Size(31, 45),
                            strokeWeight: 1,
                            strokeColor: "#161F35",
                        }
                }
                shopMarkers[id] = new window.google.maps.Marker(marker);
            }
            
            //zoom/pan interaction listener
            map.addListener('bounds_changed', () => {
                clearTimeout(lazyRelist);
                lazyRelist = setTimeout(() => {
                    mapBoundChange.value++;
                }, 100);
            });
            
            //if status changes, update markers
            watch([derivedStatus,], () => {
                if (trailerMarkers.length === 0){
                    return;
                }
                let ids = Object.keys(equipment);
                for (let i in ids){
                    setTrailerMarkerStatus(ids[i]);
                }
                trailerClusterer.value.forceUpdate();
            }, {deep: true});
        };


        //Load up once all promises are fulfilled. 
        Promise.all(promises).then((response) => {
            console.log("Loaded fired");
            loaded.value = true;
            return response;
        });
        
        return {
            loaded,
            detailView,
            filterOpen,
            statusFilter,
            pmFilter,
            makeFilter,
            yearFilter,
            collapsibles,
            ServiceStatusDisplay,
            searchTerm,
            selection,
            cardWrapper,

            output,
            listValue,
            console,
            
            derivedStatus,

            mapLock,
            toggleShops,
            shopMarkersActive,
            mapSetup,

            equipment,
            gpsData,
            filteredEquipment,
            shops,
            makes,
            
            trailerClusterer,
            clusteredTrailers,
            trailerMarkers,
            activeTrailerMarkers,
        }
    },
}
</script>

<style lang="scss">
@import '../assets/scss/variables';

.fleet-dashboard{

    .sidebar-left{
        position: relative;
        width: 320px;
    }



    .cards{
        scroll-behavior: smooth;
        .card{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }
    }

    .card-icons{
        position: absolute;
        top: $space-wide;
        right: 10px;
        line-height: 24px;

        img{
            height: 20px;
            width: 20px;
        }
    }

    .card-icon{
        flex: 0 0 auto;
        height: 40px;
        width: 40px;
        line-height: 40px;
        border-radius: 20px;
        text-align: center;
        color: $white;
        background: nth($grays, 7);
        margin-right: $space-standard;
        font-size: 20px;

        &.success{
            background: $success;
        }
        &.warning{
            background: $warning;
        }
        &.danger{
            background: $danger;
        }
    }

    .map{
        position: relative;
        height: 100%;
        overflow: hidden;
        
        canvas {
            paint-order: stroke fill;
        }
        .gm-style-mtc {
            button {
                border-radius: 0;
            }
        } 
        .gm-style{
            font-family: $font-fa;
            font-weight: 900;
        }

        &::after, &::before{
            content: "";
            position: absolute;
            display: block;
            z-index: 20;
            top: 0;
            left: -1px;
            bottom: 0;
            width: 1px;
            box-shadow: 2px 0 4px rgba(0,0,0,0.3);
        }
        &::before{
            top: -1px;
            left: 0;
            right: 0;
            width: auto;
            bottom: auto;
            height: 1px;
            box-shadow: 0 3px 5px rgba(0,0,0,0.4);
        }
    }
    .map-controls{
        position: absolute;
        right: 60px;
        top: 10px;
        z-index: 50;
        display: flex;
        text-align: center;
        
        >span{
            line-height: 40px;
            width: 40px;
            background: $white;
            box-shadow: 0 1px 4px -1px rgba(0,0,0,0.3);
            border-radius: 2px;
            color: #565656;
            cursor:pointer;
            @include noselect;
            
            + span{
                margin-left: $space-standard
            }

            img{
                height: 20px;
                width: 20px;
                display: block;
                margin: 10px;
                filter: saturate(0%);
            }
            
            &.active{
                color: $green;
                
                img {
                    filter: saturate(100%);
                }
            }
            
            
        }
    }


}


</style>