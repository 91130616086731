<template>
    <div class="map" ref="mapDiv"></div>
    <div>{{Object.keys(map)}}</div>
    <template v-if="mapped">
        <slot name="markers" :map="map"></slot>
        <slot name="clusters" :map="map"></slot>
    </template>
</template>

<script>
import {ref} from "@vue/reactivity";
import {Loader} from "@googlemaps/js-api-loader";
import {onBeforeMount, watch} from "@vue/runtime-core";
import atlas from "../../static/MapAtlasOptions";

export default {
    props: {
        loader : {
            type: Object,
            default: () => {
                return {
                    apiKey: "AIzaSyAJ7Ms8K2vGPJ4ypu-tNQq6iEJvSFdkcMA",
                    version: "weekly",
                    libraries: ["places"]
                }
            },
        },
        options: {
            type: Object,
            default: () => {
                return {
                    center: {
                        lat: 37.0902,
                        lng: -95.7129
                    },
                    zoom: 4,
                    styles: atlas
                }
            }
        }
    },
    emits: ['map:initialized'],
    setup: (props, {emit}) => {
        
        const mapDiv = ref();
        const loaded = ref(false);
        const mapped = ref(false);
        let map = ref({});
        
        //once we have the loader and the element, create the map        
        const watcher = watch([mapDiv, loaded], () => {
            if (mapDiv === undefined || loaded.value === false){
                return;
            }
            watcher();

            map.value = new window.google.maps.Map(mapDiv.value, props.options);
            window.google.maps.event.addListener(map.value, 'bounds_changed', () => {
                mapped.value = true;
            });
            emit('map:initialized', map.value);
        });
        
        onBeforeMount(() => {
            new Loader(props.loader).load().then((result) => {
                loaded.value = true;
                return result;
            });//!!error handling
        });
        
        return {
            mapDiv,
            map,
            mapped,
        };
    }
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

</style>